import { computed } from 'vue';
import { useItemStore } from '@Store/item.module';
import type { IItem } from '@Models/Item/item.model';
import { EAuctionStatus } from '@Enums/auctionStatus.model';
import auctionKinds from '@Enums/auctionKinds';
import statusTypes from '@Enums/statusTypes';
import marginPaymentWays from '@Enums/marginPaymentWays';
import auctionCategories from '@Enums/auctionCategories';
import documentProvisionEnum from '@Enums/documentProvision';
import registryUnitWorkflowStage from '@Enums/registryUnitWorkflowStage';
import userStatusEnum from '@Enums/userStatus';
import paymentsMethods from '@Enums/paymentsMethods';
import lowerMargin from '@Enums/lowerMargin';
import notes from '@Enums/notesTypes';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';

export const useItem: () => any = () => {
  const { $i18n } = useNuxtApp();
  const offset = ref<any>(null);
  const payMarginIn = ref<any>(null);
  const itemStore = useItemStore();
  const route = useRoute();

  const item = computed<IItem>(() => {
    return itemStore.getItem;
  });
  const auctionNotStarted = computed<boolean>(() => {
    return item.value.auctionStatus === EAuctionStatus.CREATED || item.value.auctionStatus === EAuctionStatus.USED;
  });
  const auctionInProgress = computed<boolean>(() => {
    return item.value.auctionStatus === EAuctionStatus.IN_PROGRESS;
  });
  const routeItemId = computed<string>(() => {
    return (route.params.itemId as string).split('?')[0].split('&')[0];
  });
  const auctionIsKnocked = computed<boolean>(() => item.value.auctionStatus === EAuctionStatus.KNOCKED);
  const auctionEnded = computed<boolean>(() => item.value.auctionStatus === EAuctionStatus.CLOSED);
  const auctionNotEventuated = computed<boolean>(() => item.value.notEventuated);
  const checkIsOrdinaryRealEstate = computed<boolean>(() => {
    return (
      item.value.auctionKind === auctionKinds.ORDINARY && item.value.auctionCategory === auctionCategories.REAL_ESTATE
    );
  });
  const isMovable = computed<boolean>(() => {
    return item.value.auctionCategory === auctionCategories.MOVABLE;
  });
  const showAuctionDetails = computed<boolean>(() => {
    return auctionNotStarted.value || auctionInProgress.value;
  });
  const setMessageText = (message: any) => {
    if (message.requestType == notes.INFO_DOCUMENT_ORIGINAL || message.requestType == notes.INFO_PRINCIPAL_ORIGINAL) {
      return $i18n.t('notes.messagesTitle.infoOriginal');
    }
    return null;
  };
  const setText = () => {
    return item.value.bailiff ? $i18n.t('sendDateText') : $i18n.t('receiptDate');
  };
  const price = computed<string>(() => {
    return item.value.userContent.maxBid ? item.value.userContent.maxBid : item.value.openingValue;
  });

  const userStatus = computed<string>(() => {
    const status: { [key: string]: string } = {
      [registryUnitWorkflowStage.APPROVED]: $i18n.t('bidderStatus.approved'),
      [registryUnitWorkflowStage.FORMAL_DEFECT]: $i18n.t('bidderStatus.formalDefect'),
      [registryUnitWorkflowStage.WAIT_FOR_APPROVE]: $i18n.t('bidderStatus.waitForApprove'),
      [registryUnitWorkflowStage.EXCLUDED]: $i18n.t('bidderStatus.excluded'),
      [registryUnitWorkflowStage.FORFEIT]: $i18n.t('bidderStatus.forfeit'),
      [registryUnitWorkflowStage.RESIGNED]: $i18n.t('bidderStatus.resignation')
    };
    return status[item.value.userContent.registryUnitWorkflowStage];
  });

  const isPrincipalOrConfirmed = computed<boolean>(() => {
    return (
      item.value.userContent.principalStatus === statusTypes.USER_REQUEST ||
      item.value.userContent.principalStatus === statusTypes.REQUEST ||
      item.value.userContent.principalStatus === statusTypes.ACCEPTED
    );
  });

  const documentProvision = computed<boolean>(() => {
    return item.value.userContent.documentProvision === documentProvisionEnum.BAILIFF_OFFICE;
  });

  const youWin = computed<boolean>(() => {
    return item.value.userContent.winner;
  });

  const showPayForItem = computed(() => {
    if (checkIsOrdinaryRealEstate.value) return false;
    return (
      youWin.value &&
      auctionIsKnocked.value &&
      !item.value.userContent.hasPaidForItem &&
      !item.value.userContent.netPaymentForItemExists
    );
  });

  const joinedIn = computed<boolean>(() => item.value && item.value.userContent.joinedIn);

  const auctionStartedByServerTime = computed(
    () => new Date(item.value.currentDate) > new Date(item.value.startAuction)
  );

  const principalConfirmed = computed<boolean>(() => {
    return (
      item.value.userContent.principalStatus === statusTypes.ACCEPTED ||
      item.value.userContent.principalStatus === statusTypes.NO_REQUEST
    );
  });

  const otherDocumentConfirmed = computed<boolean>(() => {
    return (
      item.value.userContent.otherDocumentStatus === statusTypes.ACCEPTED ||
      item.value.userContent.otherDocumentStatus === statusTypes.NO_REQUEST
    );
  });

  const showBidContainer = computed<boolean>(() => {
    return (
      (auctionNotStarted.value || auctionInProgress.value) &&
      principalConfirmed.value &&
      otherDocumentConfirmed.value &&
      ((item.value.userContent.marginPaymentWay &&
        item.value.userContent.marginPaymentWay !== marginPaymentWays.LOWERED) ||
        item.value.userContent.marginPaid)
    );
  });

  const auctionExpired = computed<boolean>(() => {
    return !item.value.bailiff && !item.value.userContent.joinedIn && !item.value.userContent.canJoin;
  });

  const marginInfo = computed(() => itemStore.getMarginInfo);

  const marginLeft = computed(() => {
    return marginInfo.value && marginInfo.value.baseMargin ? marginInfo.value.marginLeft : item.value.margin;
  });

  const auctionRemitToCourt = computed<boolean>(() => {
    return item.value.auctionStatus === EAuctionStatus.REMIT_TO_COURT;
  });

  const auctionCancelled = computed<boolean>(() => {
    return item.value.auctionStatus === EAuctionStatus.CANCELLED;
  });

  const itemFromWebsocket = computed<{
    deadline: string;
    extraDuration: number;
    counter: number;
  } | null>(() => itemStore.getWebsocketBid);

  const calculateTime = (endDate: any, currentServerTime = null): string => {
    const localTime = new Date().toISOString();
    const serverTime = new Date(currentServerTime || item.value.currentDate).toISOString();
    if (!offset.value) {
      offset.value = dayjs(new Date(serverTime)).diff(new Date(localTime), 'second');
    }

    const differenceInDays = dayjs(dayjs(endDate).format('YYYY-MM-DD')).diff(
      dayjs(serverTime).format('YYYY-MM-DD'),
      'day'
    );
    if (differenceInDays >= 1) {
      const prefix = differenceInDays === 1 ? $i18n.t('day') : $i18n.t('days');
      return `${differenceInDays} ${prefix}`;
    } else {
      const differenceInSeconds = dayjs(new Date(endDate)).diff(new Date(serverTime), 's');
      if (differenceInSeconds < 0) {
        return '';
      }
      const hours = Math.trunc(differenceInSeconds / 3600);
      const minutes = Math.trunc(differenceInSeconds / 60 - hours * 60);
      const seconds = differenceInSeconds - hours * 3600 - minutes * 60;
      return `${hours} h ${minutes} m ${seconds} s`;
    }
  };

  const isErrorTypeStatus = computed<boolean>(() => {
    return (
      item.value.userContent.registryUnitWorkflowStage === registryUnitWorkflowStage.FORMAL_DEFECT ||
      item.value.userContent.registryUnitWorkflowStage === registryUnitWorkflowStage.EXCLUDED ||
      (item.value.userContent.registryUnitWorkflowStage === registryUnitWorkflowStage.WAIT_FOR_MARGIN &&
        auctionInProgress.value)
    );
  });

  const bidderStatus = computed<string>(() => {
    const status: { [key: string]: string } = {
      [userStatusEnum.APPROVED]: $i18n.t('bidderStatus.approved'),
      [userStatusEnum.FORMAL_DEFECT]: $i18n.t('bidderStatus.formalDefect'),
      [userStatusEnum.WAIT_FOR_APPROVE]: $i18n.t('bidderStatus.waitForApprove'),
      [userStatusEnum.EXCLUDED]: $i18n.t('bidderStatus.excluded'),
      [userStatusEnum.FORFEIT]: $i18n.t('bidderStatus.forfeit'),
      [userStatusEnum.RESIGNED]: $i18n.t('bidderStatus.resignation')
    };
    return status[item.value.userContent.registryUnitWorkflowStage];
  });

  const joinedButMarginNotPaid = computed<boolean>(() => {
    return (
      item.value.userContent.joinedIn &&
      !item.value.userContent.marginPaid &&
      (!item.value.userContent.marginPaymentWay ||
        item.value.userContent.marginPaymentWay === marginPaymentWays.LOWERED)
    );
  });

  const waitingForBailiffDecision = computed<boolean>(() => joinedButMarginNotPaid.value && payMarginIn.value);
  const waiverIsRequested = computed<boolean>(() => item.value.userContent.waiverStatus === statusTypes.REQUESTED);
  const marginLowered = computed<boolean>(() => item.value.userContent.loweredMarginStatus === lowerMargin.LOWERED);
  const isLoweredSet = computed<boolean>(() => {
    return (
      item.value.userContent.loweredMarginStatus === lowerMargin.LOWERED ||
      item.value.userContent.loweredMarginStatus === lowerMargin.LOWEREDTOZERO
    );
  });
  const noMarginPaymentWay = computed<boolean>(() => !item.value.userContent.marginPaymentWay);

  const marginPaidAndPaymentNotConfirmed = computed<boolean>(() => {
    return (
      (item.value.userContent.marginPaymentWay === paymentsMethods.TRANSFER ||
        item.value.userContent.marginPaymentWay === paymentsMethods.BAILIFF_OFFICE ||
        item.value.userContent.marginPaymentWay === paymentsMethods.LOWERED) &&
      !item.value.userContent.excluded &&
      !item.value.userContent.marginPaid
    );
  });

  const marginPaymentConfirmed = computed<boolean>(() => {
    return item.value.margin && item.value.userContent.marginPaid;
  });

  return {
    item,
    auctionInProgress,
    auctionNotStarted,
    routeItemId,
    auctionIsKnocked,
    auctionEnded,
    auctionNotEventuated,
    checkIsOrdinaryRealEstate,
    isMovable,
    showAuctionDetails,
    setMessageText,
    setText,
    price,
    userStatus,
    itemStore,
    isPrincipalOrConfirmed,
    documentProvision,
    showPayForItem,
    joinedIn,
    auctionStartedByServerTime,
    showBidContainer,
    auctionExpired,
    marginInfo,
    marginLeft,
    auctionRemitToCourt,
    auctionCancelled,
    itemFromWebsocket,
    calculateTime,
    isErrorTypeStatus,
    bidderStatus,
    waitingForBailiffDecision,
    waiverIsRequested,
    marginLowered,
    isLoweredSet,
    noMarginPaymentWay,
    youWin,
    marginPaidAndPaymentNotConfirmed,
    marginPaymentConfirmed,
    joinedButMarginNotPaid
  };
};
