import { defineStore } from 'pinia';
import { fetchComplaintsService, fetchWaiverForBidder, fetchComplaintById } from '@Services/complaint.service';
import type { ISingleComplaint } from '@Models/Complaint/singleComplaint.model';
import type { IComplaint } from '@Models/Complaint/complaint.model';
import type { IWaiver } from '@Models/Complaint/waiver.model';

export const useComplaintStore = defineStore('complaint', {
  state: () => ({
    complaints: null as IComplaint[] | null,
    filteredComplaints: null as IComplaint[] | null,
    chosenFilters: {} as {
      [key: string]: {
        value: string;
        text: string;
        type: string;
      };
    },
    status: 'status' as string,
    type: 'type' as string,
    bidderWaiver: null as any,
    complaint: {} as ISingleComplaint,
    toggleModalForBailiff: false as boolean,
    toggleModalForBidder: false as boolean
  }),
  actions: {
    async fetchComplaintsList(data?: any) {
      const response = await fetchComplaintsService(data);
      if (response) {
        this.complaints = response;
      }
      return response;
    },
    filterComplaints(data: { value: string; text: string; type: string }) {
      this.chosenFilters[data.type] = data;
      this.setFilterComplaints();
    },
    filterList() {
      if (this.complaints != null) {
        return this.complaints.filter((el) => {
          return el.complaintType === this.chosenFilters?.type?.value;
        });
      }

      return this.complaints;
    },
    setFilterComplaints() {
      if (this.complaints != null) {
        const filterList = this.chosenFilters?.type ? this.filterList() : this.complaints;
        if (this.chosenFilters?.status) {
          // @ts-ignore: Object is possibly 'null'.
          const sortList = filterList.filter((el) => {
            return el.complaintStatus === this.chosenFilters?.status?.value;
          });
          this.filteredComplaints = sortList;
        } else {
          this.filteredComplaints = filterList;
        }
      }
    },
    removeFilterInChosenFilter(name: string) {
      delete this.chosenFilters[name];
      if (Object.keys(this.chosenFilters).length) {
        this.setFilterComplaints();
      } else {
        this.filteredComplaints = this.complaints;
      }
    },

    async fetchWaiverForBidder(itemId: number) {
      const response: any = await fetchWaiverForBidder({ itemId });
      if (response.ok) {
        this.bidderWaiver = { ...response.object };
        return response;
      }
      return false;
    },
    async fetchComplaintById(complaintId: string) {
      const response: any = await fetchComplaintById(complaintId);
      if (response.ok) {
        this.complaint = response.object;
        return true;
      }
      return false;
    },
    setToggleModalForBailiff() {
      this.toggleModalForBailiff = !this.toggleModalForBailiff;
    },
    setToggleModalForBidder() {
      this.toggleModalForBidder = !this.toggleModalForBidder;
    }
  },
  getters: {
    getComplaints: (state): IComplaint[] | null => {
      return state.complaints;
    },
    getFilteredComplaints(state): any {
      return state.filteredComplaints;
    },
    getChosenFilter(state) {
      return state.chosenFilters;
    },
    getBidderWaiver: (state): IWaiver => {
      return state.bidderWaiver;
    },
    getComplaint: (state): ISingleComplaint => {
      return state.complaint;
    },
    getToggleModalForBailiff: (state): boolean => {
      return state.toggleModalForBailiff;
    },
    getToggleModalForBidder: (state): boolean => {
      return state.toggleModalForBidder;
    }
  }
});
