enum userStatus {
  'APPROVED' = 'APPROVED',
  'FORMAL_DEFECT' = 'FORMAL_DEFECT',
  'WAIT_FOR_APPROVE' = 'WAIT_FOR_APPROVE',
  'EXCLUDED' = 'EXCLUDED',
  'FORFEIT' = 'FORFEIT',
  'RESIGNED' = 'RESIGNED',
  'WAIT_FOR_MARGIN' = 'WAIT_FOR_MARGIN',
  'DELETED' = 'DELETED'
}
export default userStatus;